<template>
  <div class="cmp-calculation-box utlz-col-sm-12">
    <div class="box-wrapper">
      <div class="column-label">
        <div class="col-row pane-height">
          <div class="cell pane-label">            
            <span v-translation="{ type: 'label', code: 'label_height_glass_pane' }"></span>            
          </div>
          <div class="cell pane-value">
            <div class="input-wrapper">
              <input class="pane-height" type="number" @keydown.enter="changeHeight" @change="changeHeight" v-model="paneHeight">
              <span class="unit-mm">{{ units }}</span>
            </div>
            <span class="range-values">({{minPaneHeight + units }} - {{ maxPaneHeight + units }})</span>
          </div>
        </div>
        <div class="col-row pane-width">
          <div class="cell pane-label">
            <span v-translation="{ type: 'label', code: 'label_pane_width' }"></span>
          </div>
          <div class="cell pane-value">        
            <div class="input-wrapper">    
              <input class="pane-width" type="number" @keydown.enter="changeWidth" @change="changeWidth" v-model="paneWidth">
              <span class="unit-mm">{{ units }}</span>
            </div>
            <span class="range-values">({{minPaneWidth + units }} - {{ maxPaneWidth + units }})</span>
          </div>
        </div>
        <div class="col-row panel-totals">
          <template v-if="showPrices && product.prices.length">
            <div class="col-row">
              <div class="cell pane-label">
                <span v-translation="{ type: 'label', code: 'price_custom_pane' }"></span>
              </div>
              <div class="cell pane-value">   
                <span>{{ product.prices[0].price }}</span>
              </div>
            </div>
          </template>
          <div class="col-row">
            <div class="cell pane-label">
              <span v-translation="{ type: 'label', code: 'total_sqr_mtr' }"></span>
            </div>
            <div class="cell pane-value">   
              <span>{{ sqrMeter + " "}}<span class="m2">m2</span></span>
            </div>
          </div>
          <template v-if="product.customDecimals.CUSTOM_GLASS_PRICE > 0">
            <div class="col-row">
              <div class="cell pane-label">
                <span v-translation="{ type: 'label', code: 'custom_glass_price_label' }"></span>
              </div>
              <div class="cell pane-value">   
                <span>{{ formattedPriceCustomGlass }}</span>
              </div>
            </div>
          </template>
          <template v-if="showPrices && product.prices.length">
            <div class="col-row">
              <div class="cell pane-label">
                <span v-translation="{ type: 'label', code: 'total_price' }"></span>
              </div>
              <div class="cell pane-value">
                <span>{{ totalFormattedPrice }}</span>
              </div>
            </div>
          </template>
        </div>
        </div>        
      </div>
     
    </div>
</template>

<script>
import { formatPrice } from 'src/utilities/helpers';
import { mapGetters } from 'vuex';
export default {
  props: {
    product: { type: Object, required: true },
    quantity: { type: Number, required: false, default: 1 }
  },
  data () {
    return {
      minPaneHeight: 850,
      maxPaneHeight: 2700,
      minPaneWidth: 395,
      maxPaneWidth: 2000,
      paneHeight: 850,
      paneWidth: 395,
      units: 'mm'
    };
  },
  watch: {
    quantity (val) {
      this.$emit('changedCalculations', { calcValues: this.calculationValues, totalPrice: this.totalPrice.toFixed(2) });
    }
  },
  computed: {
    ...mapGetters(['showPrices', 'bespokeGlobalWs']),
    defaultPrice () {
      return this.product.prices.length ? this.product.prices[0].rawPrice : 0;
    },
    calculationValues () {

      var paneldimensionsLabel = 'Paneelbreedte: ';
      var panelWidthLabel = 'Netto Glasmaat: ';

      if (this.bespokeGlobalWs.shopName.toLowerCase() == 'de') {
        paneldimensionsLabel = 'Netto-Glasmaß: '
        panelWidthLabel = 'Paneelbreite: ';
      }

      return `${paneldimensionsLabel}${(this.paneHeight - 85) + this.units}, ${panelWidthLabel}${this.paneWidth + this.units}`;
    },
    sqrMeter () {
      let m2 = ((this.paneHeight * this.paneWidth) / 1000000) * this.quantity;
      return m2.toFixed(2);
    },
    totalPrice () {
      let totalPrice = (((this.paneHeight * this.paneWidth) / 1000000) * this.defaultPrice) * this.quantity;
      let customGlassPrice = this.quantity * this.product.customDecimals.CUSTOM_GLASS_PRICE;
      return totalPrice + customGlassPrice;
    },
    totalFormattedPrice () {
      return formatPrice(this.totalPrice);
    },
    formattedPriceCustomGlass () {
      return formatPrice(this.product.customDecimals.CUSTOM_GLASS_PRICE);
    }
  },
  methods: {
    changeHeight () {
      this.paneHeight = this.processValue(this.paneHeight, this.minPaneHeight, this.maxPaneHeight);
      this.$emit('changedCalculations', { calcValues: this.calculationValues, totalPrice: this.totalPrice.toFixed(2) });
    },
    changeWidth () {
      this.paneWidth = this.processValue(this.paneWidth, this.minPaneWidth, this.maxPaneWidth);
      this.$emit('changedCalculations', { calcValues: this.calculationValues, totalPrice:this.totalPrice.toFixed(2) });
    },
    processValue (value, min, max) {
      const val = Math.floor(parseInt(value));
      if (val < min) { return min; }
      else if (val > max) { return max; }
      else { return val };
    }
  },
  created () {
    this.$emit('changedCalculations', { calcValues: this.calculationValues, totalPrice: this.totalPrice.toFixed(2) });;
  }
};
</script>

<style>
</style>
