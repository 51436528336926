import axios from 'axios';

// BESPOKE THERM-46
// Added helper to retrieve currency sign, call this before 
// initiating elastic list and elastic product details
export let currencySign = '€';

export function getCurrencySign () {
  return new Promise((resolve, reject) => {
    axios.post('/bespoke/ajaxmethods.aspx/GetCurrencySign', {}).then(res => {
      currencySign = res.data.d;
      resolve(currencySign);
    });
  });  
}
// END BESPOKE THERM-46


export function formatPrice (price, decimals = 2) {
  // BESPOKE THERM-46
  // Added currencySign
  return currencySign + ' ' + price.toFixed(decimals).replace('.', ',');
  // END BESPOKE THERM-46
}

// Navigates to vb page, and gives the option to cache current store
export function navigateToHybridUrl (url, { cacheState, moduleKey, stateTree }) {
  if (cacheState) {
    window.sessionStorage[moduleKey] = JSON.stringify(stateTree);
  }
  window.location.href = url;
}

export function retrieveStore (moduleKey) {
  return JSON.parse(window.sessionStorage[moduleKey]);
}
